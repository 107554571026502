import './CwsResultPage.css';
import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-modal';
import { ref, uploadBytes, getDownloadURL, getStorage } from "firebase/storage";
import firebaseApp from '../../api/firebase';
import imgDic from '../Engine_Src/img_dic.js';
import world from '../Engine_Src/world.js';
import mainRun from '../Engine_Src/main_run.js';
import tileDic from '../Engine_Src/tile_dic.js';
import tileMapMaker from '../Engine_Src/tile_map_maker.js';
import num_to_tile_dic from '../Engine_Src/num_to_tile_dic.js';
import Swal from 'sweetalert2';
import useStore from '../GlobalStore';
import CelebrateModal from './Modal_set/CelebrateModal.js'
Modal.setAppElement('#root');

const storage = getStorage(firebaseApp);

const markers = [
    { start: '#@1-0-define_class-0-S', end: '#@1-0-define_class-0-E', indent: 0 },
    { start: '#@1-1-global_var_inF-4-S', end: '#@1-1-global_var_inF-4-E', indent: 4 },
    { start: '#@1-2-draw_inL-8-S', end: '#@1-2-draw_inL-8-E', indent: 8 },
    { start: '#@1-3-user_init-4-S', end: '#@1-3-user_init-4-E', indent: 4 },
    { start: '#@1-4-user_loop-8-S', end: '#@1-4-user_loop-8-E', indent: 8 },
    { start: '#@1-5-event-0-S', end: '#@1-5-event-0-E', indent: 0 },
    { start: '#@0-6-class_init_var-8-S', end: '#@0-6-class_init_var-8-E', indent: 8 },
    { start: '#@0-7-class_func-4-S', end: '#@0-7-class_func-4-E', indent: 4 },
    { start: '#@0-8-user_define_class-0-S', end: '#@0-8-user_define_class-0-E', indent: 0 },
    { start: '#@1-9-user_run_func-0-S', end: '#@1-9-user_run_func-0-E', indent: 0 },
    { start: '#@1-10-backGround-0-S', end: '#@1-10-backGround-0-E', indent: 0 },
    { start: '#@1-11-masterCode-0-S', end: '#@1-11-masterCode-0-E', indent: 0 },
    { start: '#user-add-img-S', end: '#user-add-img-E', indent: 0 },
    { start: '#user-bg-add-tile-dic-S', end: '#user-bg-add-tile-dic-E', indent: 0 },
    { start: '#user-bg-add-num-dic-S', end: '#user-bg-add-num-dic-E', indent: 0 },
];

function formatText(text) {
    return text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));
}

function insertCode(data, marker, userCode) {
    const startIndex = data.indexOf(marker.start);
    const endIndex = data.indexOf(marker.end);

    if (startIndex === -1 || endIndex === -1 || startIndex > endIndex) {
        throw new Error('Invalid markers or markers not found');
    }

    const beforeCode = data.substring(0, startIndex + marker.start.length);
    const afterCode = data.substring(endIndex);
    const indentSpaces = ' '.repeat(marker.indent || 0);
    const indentedUserCode = userCode.split('\n').map(line => indentSpaces + line).join('\n');
    return beforeCode + '\n' + indentedUserCode + '\n' + afterCode;
}

function CwsResultPage({ userBgDic, userImgDic, project, setProject, RunURL, setSumImg }) {
    const [floatMessage, setfloatMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [htmlContent, setHtmlContent] = useState('');
    const [isCelebrateModalOpen, setIsCelebrateModalOpen] = useState(false);
    const iframeRef = useRef(null);
    const { userId } = useStore();

    const closeModal = () => setIsModalOpen(false);
    const closeCelebrateModal = () => setIsCelebrateModalOpen(false); // Close celebration modal

    const triggerCelebrateModal = () => {
        setIsCelebrateModalOpen(true);  // Open celebration modal
    };

    const captureAndUploadImage = async () => {
        const iframe = iframeRef.current;
        if (iframe) {
            const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
            const canvasBack = iframeDocument.querySelector('#Canvas_back');
            const canvas1 = iframeDocument.querySelector('#Canvas_1');

            if (canvasBack && canvas1) {
                const tempCanvas = document.createElement('canvas');
                tempCanvas.width = 500;
                tempCanvas.height = 400;
                const context = tempCanvas.getContext('2d');

                context.drawImage(canvasBack, 0, 0);
                context.drawImage(canvas1, 0, 0);

                const imgData = tempCanvas.toDataURL('image/png');
        //        uploadToFirebase(imgData);
            } else {
                console.error('One or both canvas elements not found inside iframe.');
            }
        }
    };

    const uploadToFirebase = async (imgData) => {
        const fileName = `thumbnail.png`; // Simplified to just "thumbnail.png"
        const userFolder = `users/${userId}/${project.name}`; // Define the user folder based on userId and project name
        const storageRef = ref(storage, `${userFolder}/${fileName}`); // Use the user folder and file name for the storage reference
        const file = await urlToFile(imgData, fileName, 'image/png');
        const snapshot = await uploadBytes(storageRef, file);
        const downloadUrl = await getDownloadURL(snapshot.ref);
        console.log(downloadUrl);
        setSumImg(downloadUrl);
    };

    const urlToFile = async (url, filename, mimeType) => {
        const response = await fetch(url);
        const buffer = await response.arrayBuffer();
        return new File([buffer], filename, { type: mimeType });
    };

    useEffect(() => {
        const handleMessage = async (event) => {
            if (event.data.type === "specificMessageFound") {
                const errorMessage = event.data.message;
                console.log(event.data.message);
                let viewingMessage = '';
                if (errorMessage.includes('SyntaxError')) {
                    viewingMessage = `메시지:${errorMessage}\nSyntaxError가 감지되었습니다: 문법 오류를 확인하세요!\n1) 괄호나 ""의 짝을 맞췄는지 확인하세요!\n2) 들여쓰기가 잘못되어 있을 수 있습니다!!\n3) : 을 했는지 확인하세요!`;
                } else if (errorMessage.includes('ReferenceError')) {
                    viewingMessage = `메시지:${errorMessage}\nReferenceError가 감지되었습니다: 선언되지 않은 변수를 사용하고 있지 않은지 확인하세요!`;
                } else if (errorMessage.includes('unexpected indent')) {
                    viewingMessage = `메시지:${errorMessage}\n에러가 발생했습니다...\n들여쓰기가 잘못되어 있을 수 있습니다!!`;
                } else {
                    viewingMessage = `메시지:${errorMessage}\n에러가 발생했습니다...`;
                }
                setfloatMessage(viewingMessage);
                setIsModalOpen(true);
            } else if (event.data.type === "pythonErrorCompleted") {
                const errorMessage = event.data.message;
                console.log(errorMessage);
                let viewingMessage = '';
                if (errorMessage.includes('not defined')) {
                    viewingMessage = `python error가 발생했습니다..\n정의되지 않은 것을 사용했습니다!\n메시지:${errorMessage}`;
                } else {
                    viewingMessage = errorMessage;
                }
                setfloatMessage(viewingMessage);
                setIsModalOpen(true);
            } else if (event.data.type === "gameCompleted") {
                triggerCelebrateModal();
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    useEffect(() => {
        console.log("!!!");
        console.log(RunURL);
        if (RunURL === 'frontend_run') {
            
            console.log("compile start");
            const generateHTML = () => {
                let combinedCode = tileDic + '\n' + num_to_tile_dic + '\n' + imgDic + '\n' + tileMapMaker + '\n' + world + '\n' + mainRun;
                console.log(project);
                if (project.userImgDic && project.userImgDic.length > 0) {
                    const userImgDicCode = project.userImgDic.map(entry => entry.code).join('\n');
                    combinedCode = insertCode(combinedCode, markers[12], userImgDicCode);
                }

                if (project.userBgDic && project.userBgDic.length > 0) {
                    const userBgDicCode = project.userBgDic.map(entry => entry.code).join('\n');
                    combinedCode = insertCode(combinedCode, markers[13], userBgDicCode);

                    const numBgEntries = project.userBgDic.map(entry => {
                        const code = entry.code;
                        const name = code.split(':')[0].trim();
                        const imageNum = name.split('_')[1].split('"')[0].trim();
                        return `${imageNum} : ${name},`;
                    });

                    const userNumBgDicCode = numBgEntries.join('\n');
                    combinedCode = insertCode(combinedCode, markers[14], userNumBgDicCode);
                }

                if (Array.isArray(project.compileCode) && project.compileCode.length > 0) {
                    combinedCode = insertCode(combinedCode, markers[8], project.compileCode[4]?.value || '');
                    combinedCode = insertCode(combinedCode, markers[7], project.compileCode[2]?.value || '');
                    combinedCode = insertCode(combinedCode, markers[0], project.compileCode[3]?.value || '');
                    combinedCode = insertCode(combinedCode, markers[5], project.compileCode[1]?.value || '');
                    combinedCode = insertCode(combinedCode, markers[9], project.compileCode[5]?.value || '');
                    combinedCode = insertCode(combinedCode, markers[10], project.compileCode[6]?.value || '');
                    combinedCode = insertCode(combinedCode, markers[11], project.compileCode[7]?.value || '');

                    const html = `
                        <!DOCTYPE html>
                        <html>
                        <head>
                            <title>pywebcanvas-example</title>
                            <!-- Recommended meta tags -->
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width,initial-scale=1.0">
        <!-- PyScript CSS -->
        <link rel="stylesheet" href="https://pyscript.net/releases/2024.11.1/core.css">
        <!-- This script tag bootstraps PyScript -->
        <script type="module" src="https://pyscript.net/releases/2024.11.1/core.js"></script>
                            <py-env>
                            </py-env>
                            <style>
                                canvas {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                }
                                py-script {
                                    color: black;
                                }
                                py-script output, py-script div {
                                    color: white;
                                    background-color: black !important;
                                    margin: 0 !important;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                }
                                div[id^="py-"] {
                                    display: none;
                                }
                            </style>
                        </head>
                        <body>
                            <div id="canvas-body">
                                <canvas id="Canvas_back"></canvas>
                                <canvas id="Canvas_1"></canvas>
                                <canvas id="Canvas_2"></canvas>
                            </div>
                            <script src="/pyindex.js"></script>
                            
                            <py-script>${combinedCode}</py-script>
                        </body>
                        </html>
                    `;
                    setHtmlContent(html);
                }
            };
            generateHTML();
           
        }
    }, [RunURL]);

    return (
        <>
            <iframe
                ref={iframeRef}
                srcDoc={htmlContent}
                width="100%"
                height="100%"
                id="Cws-iframe"
                display="block"
                position="relative"
                allowFullScreen
                className='Cws-res-iframe'
            />
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Error Message"
                className="Cws-res-modalBody"
                overlayClassName="Cws-res-overlay"
            >
                <div className="Cws-res-modalContent">
                    <button id="Cws-res-modalCloseBtn" onClick={closeModal}>
                        ✖
                    </button>
                    {formatText(floatMessage)}
                </div>
            </Modal>
                {/* Mission Success Celebration Modal */}
                <CelebrateModal
                isOpen={isCelebrateModalOpen}  // Control modal visibility
                onRequestClose={closeCelebrateModal}  // Close modal function
            />
        </>
    );
}

export default CwsResultPage;
